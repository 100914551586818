import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Anadamide",
  "date": "2021-01-19T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Anandamide is a lipid mediator that acts as an endogenous ligand of CB1 receptors. These receptors are also the primary molecular target responsible for the pharmacological effects of Δ9-tetrahydrocannabinol, the psychoactive ingredient in Cannabis sativa. Several studies demonstrate that anandamide exerts an overall modulatory effect on the brain reward circuitry. Several reports suggest its involvement in the addiction-producing actions of other abused drugs, and it can also act as a behavioral reinforcer in animal models of drug abuse. Importantly, all these effects of anandamide appear to be potentiated by pharmacological inhibition of its metabolic degradation. Enhanced brain levels of anandamide after treatment with inhibitors of fatty acid amide hydrolase, the main enzyme responsible for its degradation, seem to affect the rewarding and reinforcing actions of many drugs of abuse. In this review, we will provide an overview from a preclinical perspective of the current state of knowledge regarding the behavioral pharmacology of anandamide, with a particular emphasis on its motivational/reinforcing properties. We will also discuss how modulation of anandamide levels through inhibition of enzymatic metabolic pathways could provide a basis for developing new pharmaco-therapeutic tools for the treatment of substance use disorders.`}</p>
    <p>{`Cannabis sativa and its derivatives, i.e., marijuana, are among the best-known mind-altering substances used by man in ancient times and are still among the most abused substances worldwide. The major psychoactive component of marijuana, Δ9-tetrahydrocannabinol (THC), was isolated and its chemical structure clarified by Gaoni and Mechoulam in 1964 `}{`[1]`}{`. Since then, THC has been synthesized, and many studies have been conducted on its activity `}{`[2]`}{`. Nevertheless, until the discovery of CB1 receptors in 1988 `}{`[3]`}{`, the mechanisms involved in the action of THC on the brain were elusive and were suggested to consist of non-specific activity on the neuronal cell membrane `}{`[4, 5]`}{`. The discovery of CB1 receptors `}{`[6]`}{`, the primary pharmacological target of THC, prompted research to find the endogenous ligands for these receptors. The first endocannabinoid to be discovered was anandamide (AEA), which was isolated from the pig brain by William Devane and co-workers in 1992 `}{`[7]`}{`. Its name comes from the Sanskrit word “ananda” (internal bliss), which emphasizes the interesting role of AEA as an endogenous marijuana-like substance self-delivered by the brain. 2-Arachidonoylglycerol (2-AG) was the second endocannabinoid to be identified; it was isolated from the rat brain and the canine gut `}{`[8, 9]`}{`. Since the discovery of these first two endogenous cannabinoids, numerous studies have been carried out demonstrating that their behavioral and molecular effects only partially overlap between compounds, as well as between each compound and the most studied CB1 agonist, THC `}{`[10]`}{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      